import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { ACTION_CONST } from "../../constants";


const ClosedProjectsComponent = (props) => {
  const dispatch = useDispatch();
  const [tempLoading, setTempLoading] = useState([]);

  const closedProjects = useSelector((state) =>
    get(state, "project.closedProjects", [])
  );


  return (


    <div className="pp-investment pp-close">
      <div className="container text-center">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12 text-center">
            <div className="section-text">
              <h2 className="section-title">Projects Closed</h2>
              <p className="section-description"></p>
            </div>
          </div>
        </div>

        <div className="investment-section-items">
          {/* <div className="row d-flex justify-content-sm-center"> */}
          <div className="row d-flex ">
            {closedProjects.length > 0 ? closedProjects.map((item, key) => {
              return (
                <div className="col-lg-6 col-md-6 text-center" key={key}>
                  <div className="single-item">
                    {
                      item.athMultiplier &&
                      <div className="pp-top-tag">
                        <div><small>ATH</small></div>
                        <div><b>{item.athMultiplier}x</b></div>
                      </div>

                    }



                    <div className="pp-card-body">
                      <div className="pp-card-top">
                        <Link to={(item.contract && item.contract !== 'TBA') ? `project/${item.contract}` : `#`}>
                          <div className="icon-box">
                            <span><img src={item.logo} alt="#" /></span>
                          </div>
                        </Link>
                        <div className="title-box">
                          <h3 className="d-flex align-items-center">
                            <Link to={(item.contract && item.contract !== 'TBA') ? `project/${item.contract}` : `#`}>
                              <div>{item.name}</div>
                            </Link>
                          </h3>
                          <div className="item-social">
                              {
                                item.pancakeswap &&
                                <a style={{ backgroundColor: '#47d4dc' }} href={item.pancakeswap} target="_blank"><img height="18" src="/images/pancake-swap.png" /></a>
                              }
                              {
                                item.website &&
                                <a href={item.website} target="_blank"><i className="fas fa-globe"></i></a>
                              }
                              {
                                item.twitter &&
                                <a href={item.twitter} target="_blank"><i className="fab fa-twitter"></i></a>
                              }
                              {
                                item.medium &&
                                <a href={item.medium} target="_blank"><i className="fab fa-medium-m"></i></a>
                              }
                              {
                                item.telegram &&
                                <a href={item.telegram} target="_blank"><i className="fab fa-telegram"></i></a>
                              }
                            </div>
                          <Link className="items-morex" to={(item.contract && item.contract !== 'TBA') ? `project/${item.contract}` : `#`}>
                            <span className="pp-status-closed"><i className="mdi mdi-circle"></i> Closed</span>
                            <div><span className="">{item.symbol}</span></div>
                          </Link>
                        </div>
                      </div>
                      <div className="item-desc mb-1">
                        <div className="item-short-desc">{item.description}</div>
                        {
                          <div className="item-learn-more">{item.detail && <a target="blank" href={item.detail}>Learn more</a>}</div>
                        }
                      </div>
                      <Link to={(item.contract && item.contract !== 'TBA') ? `project/${item.contract}` : `#`}>
                        <div className="part-prize">
                          <div className="pp-card-info mb-3">
                            <div className="pp-card-col">Swap rate<br /><b className="nowrap">1 USD = {(item["rateUSD"])} {item["projectTokenSymbol"]}</b></div>
                            <div className="pp-card-col text-center ps-28">Cap<br /><b>{`${item.maxTotalParticipationAllocated || 0} ${item.symbol || ""}`}</b></div>
                            <div className="pp-card-col text-end">Access<br /><b>{item.isPrivate ? "Private" : "Public"}</b></div>
                          </div>
                          <div className={item.state == 'O' ? 'pp-card-progress-wrap disabled' : 'pp-card-progress-wrap'}>
                            <div className="mb-2 d-flex justify-content-between align-items-center pp-card-progress-top">
                              <div className="pp-card-col">Progress</div>
                              {item.state != 'O' && <div className="pp-card-col text-end">Participants <b className="text-participants font-12">{item['totalCountUserParticipated']}</b></div>}
                            </div>
                            <div className='pp-card-progress'>
                              {(!tempLoading || tempLoading.length <= 0) && <div title="9" className="pp-card-progress-percent" style={{ width: `${(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100}%` }}></div>}
                              {tempLoading && tempLoading.length > 0 && tempLoading.map((progress, idx) => {
                                return (<div key={idx} title="0" className="pp-card-progress-percent animation" style={{ width: progress.width + '%', left: progress.left + '%' }}></div>)
                              })}
                              <div className="pp-card-progress-label">
                                <span><b>{((item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                {item.state == 'O' && <span className="participants-center"><b className="">{item['totalCountUserParticipated']}</b> Participants</span>}
                                <span className="text-allocation"><b className="">{item.totalFundParticipated.toFixed(4)}/{item.maxTotalParticipationAllocated}</b></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })
              :
              <>

              </>
            }
          </div>
        </div>
      </div>
    </div >
    // </div>

  );
};

export default ClosedProjectsComponent;
