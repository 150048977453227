import React from "react";
const CookiePolicyPage = (props) => {
  return (
    <>
      <div className="container pb-4 pt-5">
        <p className="mb-2" style={{ textAlign: 'center' }}><b>ETHPad DIGITAL LIMITED</b></p>
        <p className="mb-2" style={{ textAlign: 'center' }}><b>Cookies Policy of <a href="https://ethpad.network/">https://ethpad.network/</a></b></p>
        <p style={{ textAlign: 'center' }}>
          <small>Last revised Date: 9<sup>th</sup> September 2021</small>
        </p>
        <>
          <h3>Cookies Information</h3>
          <p>
            These Cookies Policy (herein referred as <b>"Cookies"</b>) govern the use
            and the utilization of{" "}
            <a href="https://ethpad.network/">https://ethpad.network/</a> website
            (herein referred as <b>"website"</b>), provided by ETHP Digital Limited
            (herein referred to as <b>"ETHPAD"</b>, <b>"We"</b>, <b>"Our"</b>, and{" "}
            <b>"Us"</b>), Cookies are small text files that are placed on your computer
            by the websites that you visit. They are widely used in order to make
            websites work, or work more efficiently, as well as to provide information
            to the owners of the site. The use of cookies is now standard for most
            websites. If you are uncomfortable with the use of cookies, you can manage
            and control them through your browser, including removing cookies by
            deleting them from your 'browser history' when you leave the site. If you do
            not comfortable to the cookies utilization, please do not access or use the
            website and the Services.
          </p>
          <p>
            In order to improve our Service to you, we will occasionally use a "cookie"
            and/or other similar files or programs which may place certain information
            on your computer's hard drive when you visit our website. A cookie is a
            small amount of data that our web server sends to your web browser when you
            visit certain parts of our site. We may use cookies to:
          </p>
          <ul>
            <li>
              allow us to recognise the PC you are using when you return to our website
              so that we can understand your interest in our website and tailor its
              content to match your interests (This type of cookie may be stored
              permanently on your PC but does not contain any information that can
              identify you personally);
            </li>
            <li>
              identify you after you have logged in by storing a temporary reference
              number in the cookie so that our web server can conduct a dialogue with
              you while simultaneously dealing with other User (Your browser keeps this
              type of cookie until you log off or close down your browser when these
              types of cookie are normally deleted. No other information is stored in
              this type of cookie);
            </li>
            <li>
              allow you to carry information across pages of our site and avoid having
              to re-enter that information;
            </li>
            <li>
              allow you access to stored information if you register for any of our
              on-line services;
            </li>
            <li>
              enable us to produce statistical information (anonymous) which helps us to
              improve the structure and content of our website, to improve our Service;
            </li>
            <li>
              enable us to evaluate the effectiveness of our advertising and promotions.
            </li>
          </ul>
          <p>
            By using ETHPAD website or application you consent to the deployment of
            cookies. You can control and manage cookies using your browser (see below).
            Please note that removing or blocking cookies can impact your user
            experience and some functionality may no longer be available. Cookies do not
            enable us to gather personal information about you unless you give the
            information to our Service. Most Internet browser software allows the
            blocking of all cookies or enables you to receive a warning before a cookie
            is stored. For further information, please refer to your Internet browser
            software instructions or help screen.
          </p>
          <h3>Controlling Cookies by your browser</h3>
          <p>
            Most browsers allow you to view, manage, delete and block cookies for a
            website. Be aware that if you delete all cookies then any preferences you
            have set will be lost, including the ability to opt-out from cookies as this
            function itself requires placement of an opt out cookie on your device.
            Guidance on how to control cookies for common browsers is linked below.
          </p>
          <p>
            <b>Google Chrome</b><br />
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
              target="_blank"
            >
              https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en
            </a>
          </p>

          <p>
            <b>Mozilla Firefox</b><br />
            <a
              href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
              target="_blank"
            >
              https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&amp;redirectlocale=en-US
            </a>
          </p>

          <p>
            <b>Safari web and iOS</b><br />
            <a href="https://support.apple.com/en-us/HT201265" target="_blank">
              https://support.apple.com/en-us/HT201265
            </a>
          </p>

          <p>
            <b>Microsoft Internet Explorer</b><br />
            <a
              href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              target="_blank"
            >
              https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
            </a>
          </p>

          <p>
            Alternatively, information on deleting or controlling cookies is available
            at{" "}
            <a href="http://www.allaboutcookies.org" target="_blank">
              http://www.allaboutcookies.org
            </a>
            .
          </p>
          <p>
            For information on additional browsers and device type please see{" "}
            <a href="http://www.aboutcookies.org/" target="_blank">
              http://www.aboutcookies.org/
            </a>{" "}
            or{" "}
            <a href="http://www.cookiecentral.com/faq/" target="_blank">
              http://www.cookiecentral.com/faq/
            </a>
            .
          </p>
          <h3>Type of Cookies</h3>
          <p>
            <u>Strictly Necessary Cookies</u>: These cookies are essential to enable you
            to log in, navigate a website, and use its features or to provide a service
            requested by you. We will not need to obtain your consent in order to use
            these cookies.
          </p>
          <p>
            <u>Functionality Cookies</u>: These cookies allow the website to remember
            choices you make (such as your username, language, or the region you reside
            in) and provide enhanced, more personal features. The information these
            cookies collect remains anonymous, and they cannot track your browsing
            activity on other websites.
          </p>
          <p>
            <u>Performance cookies</u>: These cookies collect information about how you
            use a website, for example, which pages you go to most often, how much time
            you spend on a page, record difficulties you may experience while using the
            website such as error messages. All information collected by these cookies
            is aggregated and therefore anonymous. It is only used to improve the
            efficiency of the website.
          </p>
          <p>
            <u>Targeting Cookies or Advertising Cookies</u>: These cookies are used to
            deliver advertisements tailored to you and your interests specifically. They
            are also used to limit the number of times you see an advertisement as well
            as help measure the effectiveness of the advertising campaign. These cookies
            remember that you have visited a website, and this information is shared
            with other organizations such as advertisers. Quite often targeting or
            advertising cookies will be linked to the sites' functionality provided by
            the other organizations.
          </p>
          <p>
            For any questions regarding this Cookies Policy, your Cookies management or
            if you would like to contact is about the cookies, please email{" "}
            <a href="mailto:support@ethpad.network">support@ethpad.network</a> and visit
            Our website at <a href="https://ethpad.network/">https://ethpad.network/</a>
            .
          </p>
          <p>
            ETHPAD reserves the right to amend its prevailing Cookies Policy at any time
            and will place any such amendments at{" "}
            <a href="https://ethpad.network/">https://ethpad.network/</a>. This Cookies
            Policy is not intended to, nor does it, create any contractual rights
            whatsoever or any other legal rights, nor does it create any obligations on
            ETHPAD in respect of any other party or on behalf of any party.
          </p>
        </>

      </div>
    </>
  );
};

export default CookiePolicyPage;
