import { combineReducers } from 'redux';
import alert from './alert.reducer';
import utils from './utils.reducer';
import project from './project.reducer';
import wallet from './wallet.reducer';
import language from './language.reducer';
const reducers = combineReducers({
    alert,
    utils,
    project,
    wallet,
    language
});

export default reducers;