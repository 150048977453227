import { ACTION_CONST } from "../../constants";

const INITIAL_STATE = {
    language: 'cn'
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_CONST.LANGUAGE_CHANGE:
            localStorage.setItem('TRONPAD_LANGUAGE', action.language);
            return {
                ...state,
                language: action.language,
            };
        default:
            return state;
    }
};