import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { history } from "../../utils/history";
import { ACTION_CONST, ROUTES } from "../../constants";
import moment from 'moment'
import { ETH_EXPLORER } from "../../_configs";
import TimeAgo from 'react-timeago';
import "react-block-ui/style.css";
import InvestmentModal from "./InvestModal";
import { actGetListProjects, actSelectedProject, actSetCurrentContract, actSetListContractSelected } from "../../redux/action/user";
import { getCountDown, getRemainTime } from "../../utils/helper";
import ApproveModal from "./ApproveModal";
import { helpers } from "../../utils/helpers";
import ScheduleTabComponent from "./ScheduleTabComponent";
import YourAllocationComponent from "./YourAllocationComponent";
import { useParams } from "react-router";

const ProjectDetailPage = (props) => {
  const dispatch = useDispatch();

  let params = useParams();
  const [contractAddress, setContractAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [remainingAllocation, setMaxTokenAllocation] = useState(0);
  const [userParticipation, setUserParticipation] = useState(0);
  const [amountPurchased, setAmountPurchased] = useState(0);
  const [countBuy, setCountBuy] = useState(0);
  const [enableJoinProject, setEnableJoinProject] = useState(false);
  // const [enableApprove, setEnableApprove] = useState(false);
  const [projectState, setProjectState] = useState(null);
  const [tier, setTier] = useState("");
  const [roundState, setRoundState] = useState(0);
  const [textRoundState, setTextRoundState] = useState('')
  const [roundTime, setRoundTime] = useState(0);
  // const [allocationNumber, setAllocationNumber] = useState(0);
  const [allocationInfo, setAllocationInfo] = useState([])
  const [layoutAllocation, setLayoutAllocation] = useState(1)
  const [yourAllocationVisible, setYourAllocationVisible] = useState(false)
  // const [init, setInit] = useState(true)
  const [walletInfo, setWalletInfo] = useState({
    remainingAllocation: 0,
    tokenBalance: 0,
  })



  useEffect(() => {
    const { contract } = params
    if (contract && typeof contract === "string") {
      setContractAddress(contract);
      dispatch(actSelectedProject(contract));
      dispatch(actSetCurrentContract(contract));
    } else {
      history.push(ROUTES.HOMEPAGE);
      return;
    }
  }, [])



  const latestBlock = useSelector((state) =>get(state, "utils.latestBlock", 0));

  const selectedProject = useSelector((state) =>
    get(state, "project.selectedProject", null)
  );

  const showModalHelp = useSelector((state) =>
    get(state, "utils.showModalHelp", false)
  );

  const isConnectWallet = useSelector((state) =>
    get(state, "utils.isConnectWallet", false)
  );

  const web3Utils = useSelector((state) =>
    get(state, "utils.web3Utils", null)
  );

  const roundInfo = useSelector((state) =>
    get(state, "project.selectedProject.infoRound", [])
  );

  const [closeTime, setCloseTime] = useState(0);
  const [openTime, setOpenTime] = useState(0)
  const [fcfsOpenTime, setFcfsOpenTime] = useState(0)

  //Job interval  
  useEffect(()=>{
    if(contractAddress){
      dispatch(actSelectedProject(contractAddress))
    }
   
  },[latestBlock, contractAddress])

  useEffect(() => {
    if (selectedProject) {

      if (selectedProject.closeTime !== closeTime) {

        setCloseTime(selectedProject.closeTime);
      }
      if (selectedProject.openTime != openTime) {

        setOpenTime(selectedProject.openTime)
      }
      if (selectedProject.fcfsOpenTime != fcfsOpenTime) {
        console.log("fcfsOpenTime==>", selectedProject.fcfsOpenTime);
        setFcfsOpenTime(selectedProject.fcfsOpenTime)
      }

      setYourAllocationVisible(selectedProject.yourAllocationVisible)
      setProjectState(selectedProject)
    }

  }, [selectedProject]);

  useEffect(() => {

    if (openTime > 0) {
      // console.log("call here")
      getCountDown(`idOpenTime-${selectedProject["contract"]}`, openTime * 1000, (job) => {

        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_OPEN,
          data: job
        })

      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }

  }, [openTime])

  useEffect(() => {
    if (closeTime > 0) {
      console.log("idTimeClose==>", closeTime);

      getCountDown(`idTimeClose-${selectedProject["contract"]}`, closeTime * 1000, (job) => {

        //do smt when start new count down
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_CLOSE,
          data: job
        })
      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }
  }, [closeTime])

  useEffect(() => {
    if (fcfsOpenTime > 0) {
      console.log("fcfsOpenTime==>", fcfsOpenTime);
      getCountDown(`idFcfsOpenTime-${selectedProject["contract"]}`, fcfsOpenTime * 1000, (job) => {

        //do smt when start new count down
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_FCFS_TIME,
          data: job
        })
      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }
  }, [fcfsOpenTime])





  useEffect(() => {

    //round time dc chay lai khi call wallet ==> sinh ra nhieu rountime nay
    if (roundTime > 0 && selectedProject) {
      getCountDown(`idRoundTime-${selectedProject["contract"]}`, roundTime * 1000, function start(job) {
        //do smt when countdown expired
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_ROUND,
          data: job
        })

      }, function end(job) {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))
        //do smt when countdown expired
      });
    }

  }, [roundTime])


  let count = 0;
  useEffect(() => {

    if (isConnectWallet && web3Utils && projectState) {
      dispatch(actGetListProjects())
      // console.log("count===>", count++);

      fetchData()

    }
    async function fetchData() {
      const { contract } = params

      if(contract){
        await getWalletInfo(contract);
        // await getAllowance();
        await getYourAllocation(contract)
      }
    }

  }, [isConnectWallet, web3Utils, countBuy, contractAddress, projectState, latestBlock]);



  const getWalletInfo = async (contract) => {
    if (web3Utils) {
      web3Utils.getInfoWallet(contract).then(

        data => {
          // console.log(projectState);
          setWalletInfo({
            remainingAllocation: data.remainingAllocation / 10 ** projectState.decimal,
            tokenBalance: data.tokenBalance / 10 ** projectState.decimal,
            ethBalance: data.ethBalance
          })

          setEthBalance(data.ethBalance);
          setTokenBalance(data.tokenBalance / 10 ** projectState.decimal);

          setUserParticipation(data.userParticipation / 10 ** projectState.decimal);
          setMaxTokenAllocation(data.remainingAllocation / 10 ** projectState.decimal);
          setTier(data.tier);
          setRoundState(data.roundState);
          setTextRoundState(data.roundStateText)

          if (document.getElementById('idTextRoundState')) {
            document.getElementById('idTextRoundState').innerHTML = data.roundStateText;
          }


          //check 
          // setYourAllocationVisible(false)


          if (roundTime != data.roundTimestamp) {
            setRoundTime(data.roundTimestamp);
          }

          if (!(data.roundState === 1 || data.roundState === 3)) {
            console.log('call here state ')
            setEnableJoinProject(false);
            return;
          }


          //enable disable join button


          // console.log("projectState.state", projectState.state);
          if (projectState.state === "C" || projectState.state === "P" || projectState.address === 'TBA') {
            setEnableJoinProject(false)
            return;
          }
          else {
            if (selectedProject.isPrivate) {
              // debugger
              // console.log("data.remainingAllocation ==>", data.remainingAllocation);
              if (data.remainingAllocation === 0) {

                setEnableJoinProject(false)
                return;
              } else {
                setEnableJoinProject(true)
              }
            } else {
              setEnableJoinProject(true)
              setMaxTokenAllocation(projectState.maxSingleParticipationAllocated)
              // remainingAllocation = maxSingleParticipationAllocated
            }
          }

        }
      ).catch(err => {

        console.log(err);
      })
    }

  }


  const getYourAllocation = (contract) => {
    web3Utils.getInfoAllocations(contract).then(data => {
      setLayoutAllocation(data.layout)
      setAllocationInfo(data.infoAllocation)
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <>
      {selectedProject ?
        <div className="pp-detail-page">

          <div className="pp-detail-banner">
            <div className="container">

              <div className="row align-items-center justify-content-between" >
                <div className="col-lg-5 col-md-5">
                  <div className="pp-card-top animation">
                    <div className="icon-box">
                      <span><img src={selectedProject.logo} alt={selectedProject.name} /></span>
                    </div>
                  </div>
                  <div className="ms-0">
                    <div className="pp-card-top">
                      <div className="title-box">
                      <h2>
                          {selectedProject["name"]}
                          {
                            selectedProject.pancakeswap &&
                            (<a href={selectedProject.pancakeswap} target="_blank">
                              <img height="20" src="/images/pancake-swap.png" />
                            </a>)
                          }
                          {
                            selectedProject.website &&
                            (<a href={selectedProject.website} target="_blank">
                              <i className="fas fa-globe-americas"></i>
                            </a>)
                          }

                          {
                            selectedProject.twitter &&
                            (<a href={selectedProject.twitter} target="_blank">
                              <i className="fab fa-twitter"></i>
                            </a>)
                          }
                          {
                            selectedProject.medium &&
                            (<a href={selectedProject.medium} target="_blank">
                              <i className="fab fa-medium-m"></i>
                            </a>)
                          }
                          {
                            selectedProject.telegram &&
                            (<a href={selectedProject.telegram} target="_blank">
                              <i className="fab fa-telegram-plane"></i>
                            </a>)
                          }
                        </h2>
                        <div className="items-morex">

                          {(selectedProject.state === "O" || selectedProject.state === "F") &&
                            <>
                              <span className="pp-status-open"><i className="mdi mdi-circle"></i> Open</span>
                            </>
                          }
                          {selectedProject.state === "C" &&
                            <>
                              <span className="pp-status-closed"><i className="mdi mdi-circle"></i> Closed</span>
                            </>

                          }
                          {
                            selectedProject.state === "P" &&
                            <>
                              {
                                selectedProject.openTime !== undefined ?
                                  <span className="pp-status-opening"><i className="mdi mdi-circle"></i> Opens in <b id={`idOpenTime-${selectedProject["contract"]}`}>0d 0h 0m 0s</b></span>
                                  : <span className="pp-status-opening"><i className="mdi mdi-circle"></i>TBA</span>
                              }
                            </>
                          }
                          <div><span className="">{selectedProject.symbol}</span></div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <p className="text-muted">
                        {selectedProject.description}
                      </p>
                      {/* <h6>{selectedProject["contract"]}</h6> */}
                    </div>
                    <div>
                      {
                        !isConnectWallet ?
                          <button type="button" className="btn btn-lg btn-primary me-2" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"}
                          >Connect Wallet</button>
                          :
                          <>
                            <button type="button" className="btn btn-lg btn-primary me-2" data-bs-toggle="modal" data-bs-target="#buyModal"
                              disabled={!enableJoinProject}>Join Pool</button>
                          </>
                      }
                    </div>
                    <div>

                    </div>
                  </div>
                </div>


                {selectedProject.state !== "P" &&
                  <div className="col-lg-6 col-md-7">
                    <div className="card card-img-holder bg-gradient-danger mx-auto mx-lg-0">
                      <div className="card-body px-3 py-2">
                        {isConnectWallet &&
                          <>
                            <div className="pp-card-info mt-2">
                              <div className="pp-card-col">
                                Your balance<br />
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="mb-0"><strong className="text-white" id="idBusdBalance">{helpers.formatNumberDownRound(tokenBalance, 6)} {selectedProject.symbol}</strong></div>
                                </div>
                              </div>
                              <div className="pp-card-col">
                                Your tier<br />
                                <div className="mb-0 text-white"><b>{tier}</b></div>
                              </div>
                            </div>
                            <hr className="mb-2" />
                          </>
                        }

                        {/* If not connect show open time  else show round time.(if state = 4 ==> show closes)*/}
                        {
                          (selectedProject.state == "O") &&
                          <div className="pp-card-info mt-2">
                            {isConnectWallet ?
                              roundState !== 4 ?
                                <div className="pp-card-col w-200px">
                                  <span id="idTextRoundState"> {textRoundState}</span>

                                  <br />
                                  <b id={`idRoundTime-${selectedProject["contract"]}`}> 0d 0h 0m 0s</b>
                                </div> :
                                <div>ROUND CLOSED</div>
                              :
                              <div className="pp-card-col w-200px">First Come First Serve <u>opens</u> in:<br />
                                <b id={`idFcfsOpenTime-${selectedProject["contract"]}`}> 0d 0h 0m 0s</b>
                              </div>
                            }
                          </div>
                        }

                        {
                          selectedProject.state == "F" &&
                          <div className="pp-card-info mt-2">
                            {isConnectWallet ?
                              roundState !== 4 ?
                                <div className="pp-card-col w-200px">
                                  <span id="idTextRoundState"> {textRoundState}</span>

                                  <br />
                                  <b id={`idRoundTime-${selectedProject["contract"]}`}> 0d 0h 0m 0s</b>
                                </div> :
                                <div>ROUND CLOSED</div>
                              :
                              <div className="pp-card-col w-200px">Closing in:<br />
                                <b id={`idTimeClose-${selectedProject["contract"]}`}> 0d 0h 0m 0s</b>
                              </div>
                            }
                          </div>
                        }

                        {
                          selectedProject.state == "C" &&
                          <div className="pp-card-info mt-2 text-white">
                            <div>CLOSED</div>
                          </div>
                        }

                        {
                          isConnectWallet &&
                          <>
                            <hr className="mb-2 mt-2" />
                            <div className="pp-card-info">
                              <div className="d-flex justify-content-between w-100">
                                <div className="pp-card-col">
                                  Swapped<br />
                                  <b id="idUseParticipation">{helpers.formatNumberDownRound(userParticipation, 6)} {selectedProject.symbol}</b>
                                  <div>
                                    <span> {`
                                    ${helpers.formatNumberDownRound(userParticipation * selectedProject.rate, 6)} ${selectedProject.projectTokenSymbol}`}
                                    </span>
                                  </div>
                                </div>
                                <div className="pp-card-col w-200px">
                                  Remaining Allocation:<br />
                                  <b id="idBusdMaxBuy">{helpers.formatNumberDownRound(remainingAllocation, 6)} {selectedProject.symbol}</b>
                                </div>
                              </div>
                            </div>
                          </>
                        }
                        <hr className="mb-2 mt-2" />
                        <div className="pp-card-info">

                          <div className="d-flex justify-content-between w-100">

                            {/* Progress bar */}

                            {selectedProject.state !== "O" ?
                              <div className="pp-card-col w-200px">
                                Swap progress<br />
                                <div className="pp-card-progress">
                                  <div className="pp-card-progress-percent" style={{ width: `${(selectedProject.totalFundParticipated / selectedProject.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                                  <div className="pp-card-progress-label">
                                    <span><b>{((selectedProject.totalFundParticipated / selectedProject.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                    <span><b>{selectedProject.totalFundParticipated.toFixed(4)}/{selectedProject.maxTotalParticipationAllocated} {selectedProject.symbol}</b></span>
                                  </div>
                                </div>
                              </div>
                              :
                              <div className="pp-card-col w-200px pp-card-progress-wrap light-progress disabled">
                                <div className="pp-card-progress text-center">
                                  <div className="pp-card-progress-percent pp-card-progress-percent-card"></div>
                                  <div className="pp-card-progress-label">
                                    <span className="participants-center" >Allocation round</span>
                                    <span className="participants-center" style={{ top: "8px" }}>{selectedProject.totalCountUserParticipated} Participants</span>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }

                {selectedProject.state === "P" && isConnectWallet &&
                  <div className="col-lg-6 col-md-7">
                    <div className="card card-img-holder bg-gradient-danger mx-auto mx-lg-0">
                      <div className="card-body py-3 px-3 px-md-4">
                        {isConnectWallet &&
                          <div className="pp-card-info mt-2">
                            <div className="pp-card-col">
                              Your balance<br />
                              {
                                selectedProject.openTime !== undefined ?
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="mb-0"><strong id="idBusdBalance">
                                      {helpers.formatNumberDownRound(tokenBalance, 6) || 0}&nbsp; 
                                      {selectedProject.symbol}
                                    </strong></h4>
                                    {
                                      selectedProject.state !== "P" &&
                                      <h6 id="idBusdConvert">1 {selectedProject.symbol} = {selectedProject.rate} {selectedProject.projectTokenSymbol}</h6>
                                    }
                                  </div>
                                  : <div></div>
                              }

                              {/* {selectedProject.symbol !== "BNB" &&
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="mb-0"><strong id="idethBalance">{helpers.formatNumberDownRound(ethBalance, 4) || 0} BNB</strong></h4>
                                </div>
                              } */}
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }

              </div>
            </div>
          </div>

          <div className="pp-detail-content pt-1">
            <div className="container">
              <ul className="nav nav-tabs text-end" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="home" aria-selected="true"
                    data-bs-target="#home"
                  >
                    Project details
                  </button>
                </li>
                <li className="">
                  <button className="nav-link" id="schedule-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="schedule"
                    data-bs-target="#schedule"
                    aria-selected="true">
                    Schedule
                  </button>
                </li>
                {yourAllocationVisible &&
                  <li className="">
                    <button className="nav-link" id="allocation-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="allocation"
                      data-bs-target="#allocation"
                      aria-selected="true">
                      Your Allocation
                  </button>
                  </li>
                }

              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className="py-3">

                    <div className="row">
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-header">Pool information</div>
                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <table className="table mb-0 pp-table-info">
                                <tbody>

                                  <tr>
                                    <td>Opens</td>
                                    {
                                      selectedProject.openTime === undefined ?
                                        <td className="text-right">TBA</td>
                                        : <td className="text-right">{moment(selectedProject.openTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC </td>
                                    }
                                  </tr>
                                  <tr>
                                    <td>FCFS Opens</td>
                                    {
                                      selectedProject.fcfsOpenTime === undefined ?
                                        <td className="text-right">TBA</td>
                                        : <td className="text-right">{moment(selectedProject.fcfsOpenTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC </td>
                                    }
                                  </tr>
                                  <tr>
                                    <td>Closes</td>
                                    {
                                      selectedProject.closeTime === undefined ?
                                        <td className="text-right">TBA</td>
                                        : <td className="text-right">{moment(selectedProject.closeTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</td>
                                    }
                                  </tr>
                                  <tr>
                                    <td>Swap Rate</td>
                                    {
                                      selectedProject.openTime !== undefined ?
                                        <td><span style={{ textTransform: 'uppercase' }} id="idBusdConvert">1 USD = {selectedProject.rateUSD.toFixed(4)} {selectedProject.projectTokenSymbol}</span></td>
                                        : <td><span style={{ textTransform: 'uppercase' }} id="idBusdConvert">TBA</span></td>
                                    }
                                  </tr>

                                  <tr>
                                    <td>Cap</td>
                                    {
                                      selectedProject.openTime !== undefined ?
                                        <td><span style={{ textTransform: 'uppercase' }}>{selectedProject.maxTotalParticipationAllocated} {selectedProject.symbol}</span></td>
                                        : <td><span style={{ textTransform: 'uppercase' }}>TBA</span></td>
                                    }
                                  </tr>
                                  <tr>
                                    <td>Total Users Participated</td>
                                    <td className="text-right">{selectedProject.totalCountUserParticipated || 0} </td>
                                  </tr>
                                  <tr>
                                    <td>Total Funds Swapped</td>
                                    {
                                      selectedProject.openTime !== undefined ?
                                        <td className="text-right">{selectedProject.totalFundParticipated.toFixed(6) || 0} {selectedProject.symbol || ""}</td>
                                        : <td className="text-right">0</td>
                                    }

                                  </tr>
                                  <tr>
                                    <td>Access Type</td>
                                    <td className="text-right">{selectedProject.isPrivate ? "Private" : "Public"}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mt-4 mt-md-0">
                        <div className="card">
                          <div className="card-header">Token information</div>
                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <table className="table mb-0 pp-table-info">
                                <tbody>
                                  <tr>
                                    <td>Name</td>
                                    <td className="text-right">{selectedProject.name}</td>
                                  </tr>
                                  <tr>
                                    <td>Token Symbol</td>
                                    <td className="text-right">{selectedProject.projectTokenSymbol}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {/* Token Distribution */}
                                      <br />
                                    </td>
                                    <td className="text-right">
                                      {/* {moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss')} UTC */}
                                    </td>
                                    {/* <td className="text-right">{new Date(selectedProject.openTime * 1000).toISOString()}</td> */}
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ScheduleTabComponent roundInfo={roundInfo} />

                {yourAllocationVisible &&

                  <YourAllocationComponent
                    allocationInfo={allocationInfo}
                    tokenSymbol={selectedProject.projectTokenSymbol}
                    decimals={projectState.decimals}
                    contractAddress={contractAddress}
                    tokenAddress={projectState.projectTokenContract}
                    handleBuyClick={() => setCountBuy(countBuy + 1)}
                    claim={selectedProject.claim }
                    layout={layoutAllocation}
                  />
                }

              </div>
            </div>
          </div>

        </div> :
        <div className="pp-detail-page">

          <div className="pp-detail-banner">
            <div className="container">

              <div className="row align-items-center" >

                <div className="col-lg-12 col-md-12">
                  <div className="card card-img-holder bg-gradient-danger mx-auto mx-lg-0">
                    <div className="card-body py-3 px-3 px-md-4 text-center">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      }

      <InvestmentModal walletInfo={walletInfo}
        remainingAllocation={remainingAllocation} handleBuyClick={() => setCountBuy(countBuy + 1)}
        countClick={countBuy}
        tokenBalance={helpers.formatNumberDownRound(tokenBalance, 6)}
        symbol={selectedProject?.symbol}
        handleInputAmount={(n) => setAmountPurchased(n)} />

      {/* <ApproveModal walletInfo={walletInfo}
        handleBuyClick={() => setCountBuy(countBuy + 1)}
        handleInputAmount={(n) => setAmountPurchased(n)}
        tokenBalance={helpers.formatNumberDownRound(tokenBalance, 4)} /> */}


    </>
  );
};

export default ProjectDetailPage;
