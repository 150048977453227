import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";



import { actGetListProjects } from "../../redux/action/user";
import WaitingProjectsComponent from "./WaitingProjectsComponent";
import OpeningProjectsComponent from "./OpeningProjectsComponent";
import ClosedProjectsComponent from "./ClosedProjectsComponent";
import { ACTION_CONST } from "../../constants";
import { get } from "react-hook-form";

const ProjectsPage = (props) => {
  const dispatch = useDispatch();
  const latestBlock = useSelector((state) =>get(state, "utils.latestBlock", 0));

  useEffect(() => {
    // console.log("actGetProject");
    dispatch(actGetListProjects())
  }, [latestBlock])





  return (
    <>
      <div className="pp-projects-page mt-5 pt-3">

      <OpeningProjectsComponent />
        <WaitingProjectsComponent />

        {/* <OpeningProjectsComponent /> */}

        <ClosedProjectsComponent />
      </div>

    </>
  );
};

export default ProjectsPage;
