import React from 'react';
import ConnectWalletModal from '../ConnectWalletModal';
import WalletModal from '../WalletModal';
import HelpModal from '../HelpModal';
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";
import packageJson from '../../../../package.json';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="pp-footer">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="text-muted"> Copyright © {currentYear}. All Rights Reserved by ETHPad</div>
            <div className="text-muted"> v{packageJson.version}</div>
            <div className="text-end pp-footer-link">
              <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>
              <Link target="_blank" to={ROUTES.COOKIE_POLICY}>Cookies Policy</Link>
              <Link target="_blank" to={ROUTES.TERMS_OF_USE}>Terms of Use</Link>
            </div>
          </div>
        </div>
      </div>
      <ConnectWalletModal />
      <HelpModal />
      <WalletModal/>
    </>
  );
}