import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
const StakingPolicyPage = (props) => {
  return (
    <>
      <div className="container py-5">
        <div className="d-flex align-items-start tab-term">
          <div className="nav nav-pills-policy flex-column nav-pills me-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <Link to={ROUTES.TERMS_OF_USE} className="nav-link text-start" id="v-pills-terms-tab">Terms of Use </Link>
            <Link to={ROUTES.PRIVACY_POLICY} className="nav-link text-start" id="v-pills-privacy-policy-tab">Privacy Policy</Link>
            <Link to={ROUTES.COOKIE_POLICY} className="nav-link text-start" id="v-pills-cookies-policy-tab">Cookies Policy</Link>
            <Link to={ROUTES.STAKING_POLICY} className="nav-link text-start active" id="v-pills-staking-policy-tab">Staking Policy</Link>
            <Link to={ROUTES.CLICKWRAP_ACCEPTANCE} className="nav-link text-start" id="v-pills-clickwrap-acceptance-tab">Clickwrap Acceptance</Link>
          </div>
          <div className="tab-content" id="v-pills-policy-tabContent">
            <div className="tab-pane fade show active" id="v-pills-cookies-policy" role="tabpanel" aria-labelledby="v-pills-cookies-policy-tab">
              <p className="mb-2" style={{ textAlign: 'center' }}><b>BSCP DIGITAL LIMITED</b></p>
              <p className="mb-2" style={{ textAlign: 'center' }}><b>Staking Policy of <a href="https://bscpad.com/">https://bscpad.com/</a></b></p>
              <p style={{ textAlign: 'center' }}><b>Last revised Date 7</b><b>th</b><b> May 2021</b></p>
              <h1>Staking Policy</h1>
              <p><span style={{ fontWeight: 400 }}>BACPad provides Staking Service to Users for purposes of delegating (also referred to as “stake” or “staking”) the User’s BSCPad tokens in exchange fora share of Net Staking Rewards. You can provide instructions, such as delegate and undelegate through the Platform. Once the instructions are received, the system will record the instructions and you will not be able to cancel or edit those instructions. You acknowledge and agree that BACPad holds the right to reject your participation in Staking, in the event that you are unable to fulfill your identity verification requirements or for any other suspicious activity detected while participating in the services provided by BSCPad. The Tokens being staked are limited to tokens to BSCPAD tokens only. You acknowledge and agree that the estimated reward yield displayed on BSCPad is an estimation and not a guaranteed or promised actual yield. The actual yield you receive may not meet or may be lower than the estimated yield and subject to yield compression or expansion. BSCPad does not promise or guarantee the actual yield or Staking rewards you will receive. You acknowledge and agree that when you undelegate your Tokens, the delivery of such Tokens into your BEP-20 compatible wallet is 7 days and the timing may vary based on time of day of request and execution. BSCPad may levy a withdrawal fee or minimum transaction amount at its sole discretion and vary from time-to-time. BSCPas shall not be liable for any losses caused by timing differences associated with the actual delivery of the Tokens. You acknowledge and agree that BSCPad reserves the right to amend any terms related to any specific Staking program at any time in its sole discretion. BSCPad shall not be liable for any losses due to your misunderstanding of the Staking program terms and changes to such term’s rules</span></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StakingPolicyPage;
