export const ACTION_STATUS = {

    APPROVING: "APPROVING",
    APPROVED:"APPROVED",
    APPROVE_FAILS:"APPROVE_FAILS",
    CLAIM_FAIL: "CLAIM_FAIL",
    CLAIM_SUCCESS:"CLAIM_SUCCESS",
    BUY_SUCCESS :"BUY_SUCCESS",
    BUY_FAIL:"BUY_FAIL",
    STAKING_DEPOSIT_FAIL:"STAKING_DEPOSIT_FAIL",
    STAKING_DEPOSIT_SUBMIT:"STAKING_DEPOSIT_SUBMIT",
    STAKING_DEPOSIT_SUCCESS:"STAKING_DEPOSIT_SUCCESS",
    STAKING_INITIATE_WITHDRAWAL_SUBMIT:"STAKING_INITIATE_WITHDRAWAL_SUBMIT",
    STAKING_INITIATE_WITHDRAWAL_FAIL:"STAKING_INITIATE_WITHDRAWAL_FAIL",
    STAKING_INITIATE_WITHDRAWAL_SUCCESS:"STAKING_INITIATE_WITHDRAWAL_SUCCESS",
    STAKING_EXECUTE_WITHDRAWAL_SUBMIT:"STAKING_EXECUTE_WITHDRAWAL_SUBMIT",
    STAKING_EXECUTE_WITHDRAWAL_FAIL:"STAKING_EXECUTE_WITHDRAWAL_FAIL",
    STAKING_EXECUTE_WITHDRAWAL_SUCCESS:"STAKING_EXECUTE_WITHDRAWAL_SUCCESS",
    STAKING_EXECUTE_WITHDRAW_REWARDS_SUBMIT:"STAKING_EXECUTE_WITHDRAW_REWARDS_SUBMIT",
    STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL:"STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL",
    STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS:"STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS",
    STAKING_REWARDS_SUBMIT:"STAKING_REWARDS_SUBMIT",
    STAKING_REWARDS_FAIL:"STAKING_REWARDS_FAIL",
    STAKING_REWARDS_SUCCESS:"STAKING_REWARDS_SUCCESS",
    SET_KYC_SUCCESS:"SET_KYC_SUCCESS",
    SET_KYC_FAIL:"SET_KYC_FAIL",
}