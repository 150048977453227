

export const  MODE = process.env.REACT_APP_MODE || 'MAINNET';

export const POOL_INTERVAL = 15000;

export const EXPLORER = {
    MAINNET:'https://etherscan.io',
    TESTNET:'https://ropsten.etherscan.io'
}
export const BACKEND_URI = {
    // MAINNET:'https://app-ethpad.network',   //
    MAINNET:'https://app-ethpad.network',  //https://bridge.tronpad.network
    TESTNET:'http://ethpad.myfantasy.cloud'
}

export const STAKING_CONTRACT_ADDRESS ={
    MAINNET:'0x0Fa10d440522D4235c55811DC1aDf4a875C16d00',
    TESTNET:'0x5b40AA3b48500e614de160972BA092F7a71611f3'
}
export const NODE_URI ={
    MAINNET:[process.env.REACT_APP_NODE_INFURA_URI_1],
    TESTNET:['https://ropsten.infura.io/v3/f2473914890349138c8b03e3ef79d165']
}


export const KYC_BACK_END_URI="https://bscpad.com"