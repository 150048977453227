import React from "react";
const PrivacyPolicyPage = (props) => {
  return (
    <>
      <div className="container pb-4 pt-5">
        <>
          <p className="mb-2" style={{ textAlign: 'center' }}><b>ETHPad DIGITAL LIMITED</b></p>
          <p className="mb-2" style={{ textAlign: 'center' }}><b>Privacy Policy of <a href="https://ethpad.network/">https://ethpad.network/</a></b></p>
          <p style={{ textAlign: 'center' }}>
            <small>Last revised Date: 4<sup>th</sup> September 2021</small>
          </p>

          <h3>Introduction</h3>
          <p>
            This Privacy Policy describes how ETHP Digital Limited (herein referred to
            as <b>"ETHPAD"</b>, <b>"We"</b>, <b>"Our"</b>, and <b>"Us"</b>) collects,
            uses, processes, discloses, shares, transfers, and protects the information
            You provide on <a href="https://ethpad.network/">https://ethpad.network/</a>{" "}
            (<b>"ETHPAD Website"</b>). This Privacy Policy constitutes a part of the
            Terms and Conditions of the Website (<b>"T&amp;Cs"</b>). The terms used in
            this Privacy Policy will share the same definition as those stipulated in
            the T&amp;Cs except for others indicated herein.
          </p>
          <p>
            We respect and value your privacy as well as the integrity of the
            information You have provided and/or permitted Us to collect. Thus, We
            endeavour to follow industry standards to ensure the security and
            confidentiality of your Personal Information when You use Our Services or
            access ETHPAD Platform.
          </p>
          <p>
            When accessing, connecting, using, clicking on, or engaging in any Services
            provided on ETHPAD Platform, You are strongly advised to carefully read,
            acknowledge, understand, and accept all terms stipulated in this Privacy
            Policy. We will not use your information, in any shape or form, for any
            other purpose that has not been indicated in this Privacy Policy or the
            T&amp;Cs without providing You prior notification or obtaining consent from
            You.
          </p>
          <p>
            This Privacy Policy has incorporated several provisions from the General
            Data Protection Regulation (<b>"GDPR"</b>), specifically following its
            Personal Information Processing rules within the European Economic Area (
            <b>"EEA"</b>).
          </p>
          <p>
            This Privacy Policy will inform You about the following matters among
            several others:
          </p>
          <ul>
            <li>Acceptance of the Privacy Policy</li>
            <li>Our Relationship with You</li>
            <li>Personal Information that We collect</li>
            <li>How We collect Personal Information</li>
            <li>How We use Personal Information</li>
            <li>How We protect Personal Information</li>
            <li>How long We retain Personal Information</li>
            <li>Cookies</li>
            <li>Sharing your Personal Information</li>
            <li>Third-Party Collection of Personal Information</li>
            <li>Designated Countries and Your Legal Rights</li>
            <li>Rights of the residents of the EEA</li>
            <li>Cross-Border Transfer of Personal Information </li>
            <li>Limitations</li>
            <li>Updates or amendments to the Privacy Policy</li>
            <li>How to contact ETHPAD</li>
          </ul>
          <ol>
            <li>
              <b>Acceptance of the Privacy Policy</b>
              <p>
                By accessing, connecting, clicking on, using, or engaging in Our
                Services, You indicate and confirm that You have consented and agreed to
                the terms of this Privacy Policy. We will not use your Personal
                Information for any other purpose that has not been covered in this
                Privacy Policy or the T&amp;Cs without providing You prior notification
                or obtaining your consent.
              </p>
              <p>
                If You do not agree, either partially or wholly, to this Privacy Policy,
                You must immediately discontinue accessing, connecting, using, or
                engaging in Our Services and ETHPAD Platform. This Privacy Policy does
                not apply to services offered by other companies or service providers or
                other websites linked from Our Platform.
              </p>
            </li>
            <li>
              <b>Our Relationship with You</b>
              <p>
                ETHPAD has the relationship with individuals on its Services as
                described below:
              </p>
              <p>
                "User" is an individual providing personal information to Us via Our
                ETHPAD Website, Medium pages, social media accounts, or other means. For
                example, User can engage and complete additional ETHPAD social media
                engagement tasks, to entitle for add up to the whitelist for each IDO
                fundraising event or interact with Us on Our social media accounts.
              </p>
              <p>Hereinafter, We may refer to User as "You" in this Privacy Policy.</p>
            </li>
            <li>
              <b>Personal Information that We collect</b>
              <p>
                For the purposes of this Privacy Policy, Personal Information is
                information that can be used to identify, describe, relate, or associate
                with a particular individual. The following are the types of information
                We collect, store, and process when You use Our services:
              </p>
              <ol>
                <li>
                  Information provided by You when connecting with ETHPAD and for KYC
                  purpose
                  <p>
                    Prior to connecting or participating in ETHPAD Platform to use Our
                    Services, You must provide Us with your full name, birthday date,
                    nationality, home address, location, government identification
                    number (Identification Card/Passport Number and Date of the Issuance
                    of Identification Card/Passport), telegram username, digital wallet
                    address, email address, IP address, documentation for proof of
                    address and other information as We required which can be used to
                    distinguish your identity, to access the ETHPAD Platform and for the
                    Know Your Customer ("KYC") Verification purpose. Additionally, You
                    will use single sign – on (also known as "OAuth") by logging in to
                    Our Services using Metamask. These services will authenticate your
                    identity and provide You the option to share certain information
                    with Us such as your username and email address.
                  </p>
                  <p>
                    Furthermore, You must be required to upload facial image data,
                    namely photo images of your face and provide additional information
                    when firstly connecting with ETHPAD such as linking to your Facebook
                    account, Telegram, LinkedIn, GitHub, Google, or Twitter of other
                    social media accounts, and more.
                  </p>
                </li>
                <li>
                  Information collected by Us as You use Our services
                  <ol>
                    <li>
                      Usage Information
                      <p>
                        When You engage or use Our Services, We will monitor your
                        information in relation to your usage of ETHPAD Services through
                        your device such as your IP address, phone number, the type of
                        device You use to access Our Platform, device information, which
                        websites You visited before accessing Our Services or Platform,
                        browser type, Information about your browser, Date and Time You
                        visit Our Platform, Volume of data transmitted and network
                        operator. This information will either be directly obtained by
                        ETHPAD or through third party service providers.
                      </p>
                      <p>
                        We collect this type of information to ensure that Our interface
                        and/or Platform is seamlessly accessible for all users around
                        the world, except users in the prohibited jurisdictions.
                      </p>
                    </li>
                    <li>
                      Communication Information
                      <p>
                        You hereby agree that We are entitled to collect and use or
                        process the Personal Information You have provided on Our
                        Platform or generated through your usage of ETHPAD Services for
                        the purpose of being able to communicate with You through
                        messages, requests, emails, live chats, file attachments in
                        connection with your transactions on ETHPAD, or any other
                        information related to your contact with ETHPAD. If You contact
                        Us, We will keep a record of the information shared during the
                        correspondence.
                      </p>
                    </li>
                    <li>
                      Financial Information
                      <p>
                        You hereby agree that, for the purposes stipulated in this
                        Privacy Policy, We are entitled to collect and use the
                        information contained in or related to your financial
                        information when You use Our services. This includes, without
                        limitation, your transaction history such as your initial
                        cryptocurrency or token amount, your ETHPAD Token amount, your
                        staking instructions, your BEP-20 wallet address etc.
                      </p>
                      <p>
                        We collect this financial information to monitor suspicious
                        financial activities to protect You from fraud, resolution of
                        legal cases, as well as any other purposes disclosed in this
                        Privacy Policy.
                      </p>
                    </li>
                  </ol>
                  <p>
                    Parts of ETHPAD Services are public, including any information
                    disclosed on Our social media accounts, thus You acknowledge that
                    such parts of ETHPAD Services may appear on search engines or other
                    publicly available platforms, and may be "crawled", searched and
                    used by the third parties or other Users and Customers of the
                    Services. Please do not post any information that You do not wish to
                    reveal publicly.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <b>How We collect Personal Information</b>
              <ol>
                <li>
                  Information automatically collected by Us
                  <p>
                    We will collect and process Personal Information automatically
                    provided by You under the following situations.
                  </p>
                  <ol>
                    <li>when You visit and/or firstly connect with ETHPAD Website;</li>
                    <li>
                      when You voluntarily complete any user survey or provide feedback
                      to Us via emails or any other electronic channels including on Our
                      social media accounts;
                    </li>
                    <li>
                      when You use browser cookies or any other relevant software upon
                      visiting ETHPAD Website; or
                    </li>
                    <li>
                      other situations where We may automatically collect your
                      information as described in this Privacy Policy, Our T&amp;Cs, or
                      other relevant agreements with You.
                    </li>
                  </ol>
                </li>
                <li>
                  Information collected by the third-party
                  <p>
                    We will collect your Personal Information from the third parties as
                    required or permitted to the extent of applicable laws. Information
                    from the third-party sources will include, but will not limited to,
                    public databases, ID verification partners, KYC Verification
                    partners, blockchain data, marketing partners and resellers,
                    advertising partners, and analytics providers.
                  </p>
                </li>
                <li>
                  Anonymized and Aggregated Data
                  <p>
                    We will also collect other Information in the form of anonymized and
                    aggregate information where all information will be combined and
                    then removed of personally identifiable information, making it
                    unusable for the identification of specific individuals. Aggregated
                    data could be derived from your personal data, but it is not
                    considered as personal data under applicable laws. For example, We
                    may aggregate your usage data to calculate the percentage of users
                    accessing a specific features of ETHPAD Services. However, if We
                    combine or connect aggregated data with your personal data so that
                    it can directly or indirectly identify You, You acknowledge that We
                    treat the combined data as personal data which will be used in
                    accordance with this Privacy Policy.
                  </p>
                  <p>
                    We use anonymized or aggregate users' information for the purposes
                    stipulated in this Privacy Policy at any time, including gaining
                    better understanding of users' needs and behaviours, conducting
                    business intelligence and marketing initiatives, and detecting
                    security threats. In this regard, We reserve the right to implement
                    innovative technologies as they become available to be used for
                    strategic planning and operational processes.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <b>How We use Personal Information</b>
              <p>
                Your Personal Information will be made available to need-to-know basis
                to ETHPAD, Our Affiliate, Our Partners and/or within Our group of
                companies. This will include, but not be limited to, the employees
                and/or any persons or third parties working for ETHPAD who are
                responsible for using and protecting your Personal Information.
              </p>
              <p>
                We use and process your Personal Information for the following purposes
                or in the following ways:
              </p>
              <ol>
                <li>
                  To provide Our Services
                  <p>
                    We respect data protection principles, and process personal data
                    only for specified, explicit, and legitimate purpose for which such
                    personal data were provided. We primarily use your Personal
                    information, either collected or delivered, in order to enable your
                    use of ETHPAD Services (including, but not limited to, processing
                    transactions), to improve the efficiency of the Services, the
                    process to participate in the IDO fundraising project event, and to
                    verify your identity.
                  </p>
                </li>
                <li>
                  To protect users
                  <p>
                    We use the information collected to protect Our Platforms, Users'
                    information, and archives. We also use IP addresses and cookies to
                    detect suspicious activities and to protect against automated abuse
                    such as spam, phishing, and Distributed Denial of Service ("DDoS")
                    attacks, and other security risks.
                  </p>
                </li>
                <li>
                  To comply with regulations and compliance
                  <p>
                    For Our legitimate interests, We will use the information in
                    compliance with Our legal obligations, global and local industry
                    standards, and Our AML/KYC/CTF procedures. In the case where it is
                    strictly necessary (i.e., to protect the vital interests of the
                    users or other natural persons, to prevent or mitigate fraud, to
                    fulfil the purpose of public interest, or to pursue Our reasonable
                    interests), We may use and process your Personal Information without
                    obtaining your consent. You hereby expressly authorise ETHPAD to
                    disclose any and all information relating to You in ETHPAD's
                    possession to any law enforcement or government officials upon a
                    valid request.
                  </p>
                </li>
                <li>
                  For measurement, research and development purposes-
                  <p>
                    We actively measure and analyse your information to understand the
                    way You use and interact with Our Services, and Our Platform, and to
                    provide You with the unique and personalized experience. For
                    example, We allow You to use social media plugins on Our Services
                    (e.g., Telegram, Twitter, etc.), We keep track of your preferences
                    such as display name, time zone, and so on. This review is
                    continuously conducted by Our operation teams to continuously
                    improve Our Platforms' performance and to resolve issues with User
                    experience.
                  </p>
                  <p>
                    In addition, We use such information to monitor trends, and improve
                    Our Services, Our administration, the content and layout of ETHPAD
                    Website, and to develop new Services for You.
                  </p>
                </li>
                <li>
                  For communication purposes
                  <p>
                    We use your Personal Information, collected or derived from your
                    communication devices or your email address, to interact with You
                    directly, to provide You with necessary support, and/or to keep You
                    informed of wallets connected, transactions, staking instructions,
                    security details, as well as other aspects. All direct
                    communications will be appropriately maintained at ETHPAD, or the
                    service providers designated by ETHPAD, to be reviewed for accuracy,
                    retained as evidence, or used to perform other statutory
                    requirements or other obligations as stipulated in this Privacy
                    Policy and the T&amp;Cs.
                  </p>
                </li>
                <li>
                  To enforce Our T&amp;Cs, and other relevant agreements
                  <p>
                    Personal Information is also used to enforce Our T&amp;Cs
                    continuously and actively among, and other agreements relating to
                    the Services on ETHPAD Platform . Activities in this regard include,
                    but are not limited to, reviewing, processing, verifying,
                    investigating, mitigating and preventing any potentially prohibited
                    or illegal activities that may violate preceding provisions, or
                    disclose relevant information to third parties in accordance
                    therewith. In light of this, ETHPAD shall be entitled to
                    freeze/close/delete accounts or any User activity on the ETHPAD
                    Website as necessary to any User found to be engaged in any
                    activities that violate Our T&amp;Cs, and other agreements.
                  </p>
                </li>
                <li>
                  For marketing and advertising
                  <p>
                    We will share your Personal Information with Our marketing partners
                    for the purposes of targeting, modelling, and/or identifying
                    analytics as well as marketing and advertising. We may send You
                    marketing communications to alert You about new Services, yet You
                    can opt-out of Our marketing activities at any time.
                  </p>
                </li>
                <li>
                  For other purposes
                  <p>
                    Provided that we need to process or use your Personal Information
                    for other purposes, not stipulated in this Privacy Policy, We will
                    notify or request your consent to use such information. We will not
                    use your Personal Information for purposes other than the purposes
                    stipulated in this Privacy Policy without your prior consent except
                    where it is necessary for our legitimate interests (or for Our
                    Affiliate, Our Partners and/or Our group of companies) and your
                    interests and fundamental rights do not override those interests.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <b>How We protect Personal Information</b>
              <p>
                ETHPAD takes reasonable care to protect the ETHPAD's security as well as
                your Personal Information from loss, misuse, disclosure, alteration, and
                destruction. We take reasonable steps to maintain physical, technical,
                and employ procedural safeguard to ensure the confidentiality, integrity
                of your Personal Information. The safeguards include the use of
                firewalls, and data encryption, enforcing physical access controls to
                Our premise and files, and limiting access to Personal Information only
                to those employees, agents or third parties who need access to that
                information to process it for Us.
              </p>
              <p>
                We also implement certain reasonable security measures to protect your
                Personal Information from unauthorized access, and such security
                measures are in compliance with the security practices and procedures as
                prescribed under the applicable laws.
              </p>
              <p>
                However, You agree and acknowledge that it is impossible to fully
                guarantee the security of your Personal Information by implementing the
                above-mentioned safeguard and measures. It is not absolute protection to
                your Personal Information and by accessing the Services.
              </p>
              <p>
                You agree that We will not hold any responsibility for the acts
                committed by those who gain unauthorized access or abuse your
                information and services. In addition, We will not hold any
                responsibility for any act committed by the third-party service
                providers who are contractually engaged with Us to maintain an
                appropriate security measure for protecting your Personal Information.
                Thus, You are recommended to understand this responsibility and to
                independently take safety precautions to protect your Personal
                Information, particularly your credential information such your BEP-20
                wallet address. You hereby agree that We will not be liable for any
                information leakage and other damage or loss not caused by Our intention
                or gross negligence, including, but not limited to, hacker attacks,
                power interruptions, or unavoidable technical failures.
              </p>
            </li>
            <li>
              <b>How long We retain Personal Information</b>
              <p>
                You are aware that your Personal Information will continue to be stored
                and retained by Us or by a third-party processor engaged by Us for KYC
                Verification while You have been using or accessing ETHPAD Platform and
                the Services, and after your use of the Services for a reasonable period
                of time stipulated under the applicable law.
              </p>
              <p>
                In addition, We may be required by applicable laws and regulatory
                requirements to retain certain information, including your Personal
                Information, your identification verification materials, information
                relevant to AML/KYC/CTF procedures, information related to staking
                instructions, account agreements, and other agreements between Us and
                third-parties, account statements, and other records after You stop
                accessing or participating in ETHPAD.
              </p>
            </li>
            <li>
              <b>Cookies</b>
              <p>
                We will use cookies and other technologies or methods of web and
                analytic tools to gather, store, and analyse certain information related
                with your access to and activities through the Services, including when
                You visit ETHPAD Website.
              </p>
              <p>
                A "cookie" is a small piece of information that a website assigns to
                your device while You are viewing a website. Cookies are beneficial and
                may be used for various purposes. These purposes include, among other
                things, allowing You to navigate between pages efficiently, enabling
                automatic activation of certain features, remembering your preferences
                and making the interaction between You and the Services quicker, easier
                and smoother. Our ETHPAD Website will use the following types of
                cookies:
              </p>
              <ol>
                <li>
                  Strictly Necessary Cookies
                  <p>
                    These cookies are essential to enable You to log in, navigate a
                    website, and use its features or to provide a service requested by
                    You. We will not need to obtain your consent in order to use these
                    cookies.
                  </p>
                </li>
                <li>
                  Functionality Cookies
                  <p>
                    These cookies allow the website to remember choices You make (such
                    as your username, language, or the region You reside in) and provide
                    enhanced, more personal features. The information these cookies
                    collect remains anonymous, and they cannot track your browsing
                    activity on other websites.
                  </p>
                </li>
                <li>
                  Performance cookies
                  <p>
                    These cookies collect information about how You use a website, for
                    example, which pages You go to most often, how much time You spend
                    on a page, record difficulties You may experience while using the
                    website such as error messages. All information collected by these
                    cookies is aggregated and therefore anonymous. It is only used to
                    improve the efficiency of the website.
                  </p>
                </li>
                <li>
                  Targeting Cookies or Advertising Cookies
                  <p>
                    These cookies are used to deliver advertisements tailored to You and
                    your interests specifically. They are also used to limit the number
                    of times You see an advertisement as well as help measure the
                    effectiveness of the advertising campaign. These cookies remember
                    that You have visited a website, and this information is shared with
                    other organizations such as advertisers. Quite often targeting or
                    advertising cookies will be linked to the sites' functionality
                    provided by the other organizations.
                  </p>
                </li>
              </ol>
              <p>
                You may remove these cookies by following the instructions of your
                device preferences. However, if You choose to disable cookies, some
                features of ETHPAD Services may not operate properly or become
                inaccessible, and your online experience may be limited. For Further
                information please visit our Cookies Policy.
              </p>
            </li>
            <li>
              <b>Sharing your Personal Information</b>
              <p>
                We will not rent, sell, or disclose your Personal Information to any
                third parties, except to those who require access to the information to
                perform their tasks and duties under the binding agreements executed
                with Us and to share with third parties who have a legitimate purpose
                for accessing it.
              </p>
              <p>
                We may share, transfer, disclose, or allow access to your Personal
                Information to the following third parties for the purposes described
                below:
              </p>
              <ol>
                <li>
                  We may disclose Personal Information to the third parties in order to
                  administer or process a transaction, or services You have authorized
                  or requested, or in the context of facilitating the execution of a
                  transaction, or We may disclose Personal Information to third parties
                  that provide supporting services, or analytical information for the
                  purpose of the Service improvement, only where we have a lawful basis
                  to do so or under an agreement or legal requirements for the transfer
                  of Personal Information.
                </li>
                <li>
                  We will be required to verify your identities by applicable laws and
                  regulatory requirements and rely on third-party services to perform
                  these verifications. Personal Information that You provide to Us
                  during the initial connecting process is passed to these services at
                  KYC Verification process and on an ongoing basis thereafter.
                </li>
                <li>
                  We may disclose or transfer a part of your Personal Information We
                  collect if We are involved in a business transition or any merger
                  and/or acquisition (M&amp;A) projects. In such M&amp;A event, your
                  Personal Information might be among the assets transferred, but it
                  will be protected and secure in accordance with this Privacy Policy.
                </li>
                <li>
                  We may share your Personal Information with law enforcement, and
                  government officials when We are compelled to do so by a subpoena, a
                  valid court order to prevent financial loss, to report suspected
                  illegal activity or to investigate violations of any of Our T&amp;Cs
                  or any other applicable policies.
                </li>
                <li>
                  We may share your Personal Information with third-party processors
                  acting on behalf of ETHPAD or engaged by ETHPAD to process your
                  Personal Information for ETHPAD, Our Affiliate and/or Our Partners.
                </li>
              </ol>
              <p>
                All Affiliate and Third Parties with whom We share your Personal
                Information have their privacy policies. However, they will be expected
                to protect this information in a manner that aligns with the provisions
                described in this Privacy Policy.
              </p>
            </li>
            <li>
              <b>Third-Party Collection of Personal Information</b>
              <p>
                This Privacy Policy only addresses the use and disclosure of the
                Personal Information We collect from You. To the extent that You
                disclose your information to other parties through the use of Our
                Services such as by clicking on a link to any other websites of our
                partner networks, different rules regarding Privacy Policies may apply
                to their use or disclosure of the Personal Information You disclose to
                them.
              </p>
              <p>
                You hereby acknowledge that We will not be responsible for the products,
                services, or descriptions of products or services that You receive from
                the third-party websites or to the content or privacy practices of the
                third-party websites. Also, this Privacy Policy will not be applied to
                any such third-party products and services that You access through
                ETHPAD Platform. You are knowingly and voluntarily assuming all risks of
                using third-party websites to purchase products or services, and You
                agree that We will have no liability whatsoever concerning such
                third-party websites and your usage of them.
              </p>
              <p>
                Your relationship with these third parties and their services and tools
                is independent of your relationship with Us. These third parties may
                allow You to permit/restrict the information that is collected. It may
                be in your interest to individually restrict or enable such data
                collections.
              </p>
            </li>
            <li>
              <b>Designated Countries Privacy Right and Your Legal Rights</b>
              <p>
                This provision applies to Users of the Services that are located in the
                EEA, United Kingdom and/or Switzerland (herein collectively referred as{" "}
                <b>"Designated Countries"</b>) at the time of Data or Personal
                Information being collected. We may ask your information regarding your
                nationality, your location, which country You are in when You use Our
                Services or Platform, or We may rely on your IP address. We cannot apply
                this provision to Users who do not provide information about the
                location or obfuscates location information so as not to appear located
                in the Designated Countries.
              </p>
              <p>
                We use and process your Personal Information based on a valid lawful
                basis of the GDPR and any equivalent regulations (collectively referred
                as <b>"Data Protection Laws"</b>). The lawful basis for processing your
                Personal Information includes (i) your consent, (ii) performance of a
                contract, (iii) a legitimate interest, (iv) a compliance with legal
                obligations, (v) protection your vital interest, and (vi) public task.
              </p>
              <p>
                In the event of any conflict or inconsistency between any term in this
                provision and other terms contained in this Privacy Policy, the term in
                this provision will govern and control over Users in the Designated
                Countries.
              </p>
              <p>
                Under certain circumstances when You use Our Services, You have the
                rights under the Data Protection Laws in relation to your Personal
                Information. These rights include the followings:
              </p>
              <ol>
                <li>
                  Right to be informed
                  <p>
                    You reserve the right to be informed about the collection, use, and
                    process of your Personal Information.
                  </p>
                </li>
                <li>
                  Right of access
                  <p>
                    You reserve the right to make a request for a copy of the Personal
                    Information We hold about You and specific information regarding Our
                    processing of this information.
                  </p>
                </li>
                <li>
                  Right to rectify
                  <p>
                    You reserve the right to request Us to update, correct, or complete
                    your Personal Information that You believe to be outdated,
                    inaccurate, or incomplete. You will rectify at any time by informing
                    Us via our contact channels.
                  </p>
                </li>
                <li>
                  Right to Erasure (<b>"Right to be Forgotten"</b>)
                  <p>
                    You may request to have your Personal Information deleted from Our
                    records where (i) your Personal Information is no longer necessary
                    to be used for the Services or on the Platform, (ii) Personal
                    Information was collected in relation to processing that You
                    previously consented to, but later withdrew such consent, or (iii)
                    your Personal Information was collected in relation to processing
                    activities to which You object, and there are no overriding
                    legitimate grounds for Our processing.
                  </p>
                  <p>
                    Please be informed that the Right to be Forgotten mentioned above is
                    not an absolute right. We are compelled to fulfil your request only
                    where the retention of your Personal Information might cause an
                    infraction of the GDPR or applicable law to which We are subject. We
                    shall exercise reasonable efforts in having your Personal
                    Information erased to the extent required by the GDPR or applicable
                    law including in communicating erasure to Our recipients of your
                    Personal Information unless that proves to be impossible or a
                    disproportionate effort.
                  </p>
                </li>
                <li>
                  Right to data portability
                  <p>
                    You reserve the right to request to transfer a machine-readable copy
                    of your Personal Information to You or the third-party of your
                    choice. We will provide You, or third-party, your Personal
                    Information in a machine-readable format. This right only applies to
                    Personal Information You have consented Us to use.
                  </p>
                </li>
                <li>
                  Right to restrict processing
                  <p>
                    You may request Us to restrict or suppress the processing of your
                    Personal Information under certain circumstances as follows:
                  </p>
                  <ol>
                    <li>to contests the accuracy of the Personal Information;</li>
                    <li>
                      when the processing is considered unlawful, but You do not wish to
                      have your Personal Information erased;
                    </li>
                    <li>
                      where We no longer need to process your Personal Information, but
                      the information must be retained for the establishment, exercise
                      or defence of legal claims; and
                    </li>
                    <li>
                      where You have objected to Our processing your Personal
                      Information, but We need to determine whether Our legitimate
                      interest overrides your objection.
                    </li>
                  </ol>
                </li>
                <li>
                  Right to object
                  <p>
                    You may object to Our reliance on Our legitimate interests as the
                    basis of Our processing of your Personal Information that impacts
                    your rights. You may also object to Our process of your Personal
                    Information for direct marketing purposes.
                  </p>
                </li>
                <li>
                  Right to withdraw consent (<b>"Opt-out"</b>)
                  <p>
                    You reserve the right to withdraw your consent at any time where We
                    are relying on it to process your Personal Information. Withdrawing
                    your consent does not affect the lawfulness of Our processing of
                    your Personal Information prior to withdrawing.
                  </p>
                </li>
              </ol>
              <p>
                If You wish to exercise these rights as aforementioned, please inform
                and contact Us via Our Platform, including ETHPAD Website or send a
                request to{" "}
                <a href="mailto:support@ethpad.network">support@ethpad.network</a>.
              </p>
              <p>
                Please also be noted that there are some limitations when You wish to
                exercise any one of these individual rights. We may limit your
                individual rights in the following situations:
              </p>
              (i) Where denial of access is required by laws;
              <br />
              (ii) When granting access would have a negative impact on others' privacy;
              <br />
              (iii) In order to protect Our rights and properties;
              <br />
              (iv) Where the request burdensome.
              <br />
              <br />
            </li>
            <li>
              <b>Cross Border Transfer of Personal Information</b>
              <p>
                As a part of your use of and to ensure better and seamless delivery of
                the Services to You, We may store, process, analyse, transfer your
                Personal Information in location globally, including countries other
                than your home jurisdiction, and/or locations outside the Designated
                Countries where there may have data protections with less protection
                than the EU GDPR or the equivalent data protection laws.
              </p>
              <p>
                Our Service and Platform contain the transmission of your Personal
                Information to or to be transferred to or to be processed outside of the
                country where You are located or outside the Designated Countries, it is
                not suggestible if your Personal Information is restrictive.
              </p>
              <p>
                However, if You wish to continue using Our Services outside the
                Designated Countries and your home locations, We will ensure that We
                will transfer your Personal Information only to country that has the
                required contractual provisions for transferring Personal Information in
                place with the third parties to which your Information is transferred,
                or to country where is approved by the EU authorities as providing an
                adequate level of data protection or enter into legal agreements
                ensuring an adequate level of data protection in the jurisdiction of the
                party receiving the information. In this manner, You hereby expressly
                consent to this transfer, and agree that We will not be responsible for
                any additional T&amp;Cs, policies or any other guidelines implemented by
                any party receiving the information.
              </p>
            </li>
            <li>
              <b>Limitation</b>
              <ol>
                <li>
                  Our Services are not designed for the individuals who are under the
                  age of 18 or under the legal age to provide consent under the
                  applicable laws of country or jurisdiction where You are using Our
                  Services or Platform. If You are not over 18 or of the legal age of
                  your country, You will not provide any Personal Information to Us, or
                  to engage in Our services. We reserve the right to access and verify
                  any Personal Information collected from You. If We are aware that You
                  are under 18 or under the legal age who already shared your Personal
                  Information with Us, We will then discard such information and block
                  your access to ETHPAD Platform immediately.
                </li>
                <li>
                  We cannot guarantee that any losses, misuses, unauthorized accession,
                  or alteration of your Personal Information will not occur. You hereby
                  agree that You play a vital role in protecting your Personal
                  Information, including your credentials.
                </li>
              </ol>
            </li>
            <li>
              <b>Updates or amendments to the Privacy Policy</b>
              <p>
                We will revise and update this Privacy Policy periodically, at Our sole
                discretion, and the most current version will be published on ETHPAD
                Website or ETHPAD Platform (as reflected in the "Last Revised" heading).
              </p>
              <p>
                In the event of any material change which may affect your usage on Our
                Services and Platform, We will notify You by means of a prominent notice
                in advance of such change coming into effect. A prominent notice may
                include sending through your email address or other communication
                channels You provide to Us or providing You with an in-application
                notice such as a banner or pop-up alerting You of the change on the
                Website. We strongly encourage You to review this Privacy Policy
                actively. If You do not agree, either partially or wholly, the revised
                Privacy Policy, You should discontinue accessing or using Our Services.
                Your continued access to and use of Our Services after any changes to
                this Privacy Policy constitutes your consent to any changes and agree to
                continue using the Services.
              </p>
              <p>
                Furthermore, We may provide You with the "just-in-time" disclosures or
                additional information about Our data collection process, purposes for
                processing, or other information with respect to collection of your
                personal information. The purpose of the just-in-time notification is to
                supplement or clarify Our privacy practice or provide You with
                additional choices about how We process your personal information.
              </p>
            </li>
            <li>
              <b>How to contact ETHPAD</b>
              <p>
                For any questions regarding this Privacy Policy, your Personal
                Information collected or proceeded by Us, or in case You would like to
                exercise one of your legal privacy rights as stipulated in Clause 11,
                please submit your requests to{" "}
                <a href="mailto:support@ethpad.network">support@ethpad.network</a> and
                visit Our ETHPAD Website at{" "}
                <a href="https://ethpad.network/">https://ethpad.network/</a>.
              </p>
            </li>
          </ol>
        </>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
