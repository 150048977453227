import { ENDPOINTS } from "../../constants";
import axios from "axios";
import { BACKEND_URI, MODE } from "../../_configs";

export const getStakingStatus = async () => {
    try {
      const result = await axios.get(
        `${BACKEND_URI[MODE]}${ENDPOINTS.GET_STAKING_ENABLE}`
      );

      if (result.status === 200) {
        return {
          status: 200,
          data: result.data
        }
      } else {
        return {
          status: 404,
          data: null
        }
      }
    } catch (error) {
      const response = error.response;
  
      console.log(response);
  
      return {
        status: 502,
        data: null
      }
    }
  }