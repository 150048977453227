import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { isMobile } from "web3modal";
import { ACTION_CONST, ROUTES } from "../../../constants";
import { getKYC } from "../../../redux/services/kyc.api";
import { helpers } from "../../../utils/helpers";

import exactMath from 'exact-math';
import { getStakingStatus } from "../../../redux/services/staking";
import { useActiveWeb3React } from "../../../hook";
const Header = (props) => {

  const dispatch = useDispatch();
  // const [isBrowserMobile, setIsBrowserMobile] = useState(false)
  const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
  const { account, library } = useActiveWeb3React()
  const showModalHelp = useSelector((state) => get(state, "utils.showModalHelp", false));

  const latestBlock = useSelector((state) =>get(state, "utils.latestBlock", 0));
  const web3Utils = useSelector((state) => get(state, "utils.web3Utils", null));
  const stakedAmount = useSelector((state) => get(state, "wallet.stakingWalletInfo.stakedAmount", 0));
  const kycStatus = useSelector((state) => get(state, "wallet.kycStatus", null));
  const [ethPadBadBalance, setEthPadBadBalance] = useState(0);

  //set balance
  useEffect(() => {
    if (web3Utils && account) {
      //get bnb balance
      web3Utils.web3.eth.getBalance(account).then(balance => {
        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })

      //get ETHPad balance
      web3Utils.getTokenPadBalance().then(data => {
        dispatch({
          type: ACTION_CONST.GET_BSCPAD_BALANCE,
          data: data
        })
        setEthPadBadBalance(data)
      })
    }

  }, [account, web3Utils, latestBlock]);


  const handleOnclickKyc = () => {
    getKYC(account, 'url').then(data => {
      if (data) {
        const url = data.url
        window.open(url, "_blank")
      }

    }).catch(err => {
      console.log(err);
    })
  }


  // //check show hide status
  useEffect(() => {
    if (!account) {
      return;
    }
    if ((Number(ethPadBadBalance) + Number(stakedAmount)) >= 1000) {
      getKYCAddress(account)
    }
  }, [account, ethPadBadBalance, stakedAmount, latestBlock])

  //get kyc
  const getKYCAddress = (address) => {
    getKYC(address, 'state').then(response => {
      address = address.toLowerCase()
      if (response) {
        const state = response.state;
        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }

    }).catch(err => {
      console.log(err);
    })
  }

  function toggleTheme() {
    if (document.body.classList.contains('darkmode')) {
      document.body.classList.remove('darkmode');
    } else {
      document.body.classList.add('darkmode');
    }
  }



  const handGotToProject = () => {
    dispatch({
      type: ACTION_CONST.CLEAR_INTERVAL_PROJECTS_JOB
    })
  }
  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-md navbar-light bg-white">
        <div className="container">
          <a className="navbar-brand d-flex align-items-center p-0" href="https://ethpad.network/" target="_blank">
            <img src="/images/logo_w.png" height="45" alt="ETHPad" className="me-2" />
          </a>
          <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
            {isMobile() &&
              <div className="dropdown  d-block d-md-none d-lg-none d-xl-none">
                <button className="nav-link btn btn-sm btn-outline-primary btn-circle dropdown-toggle btn-helpmore" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="mdi mdi-help"></i>
                </button>
              </div>
            }
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={isConnectWallet ? 'navbar-nav ms-auto mb-2 mb-md-0 connected' : 'navbar-nav ms-auto mb-2 mb-md-0'}>

              {
                !isConnectWallet ?
                  <li className="nav-item me-2 connect-wallet-button">
                    <a className="nav-link btn btn-primary btn-sm" href="#" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"}>
                      <i className="mdi mdi-wallet-plus-outline me-1"></i>
                      <span>Connect Wallet</span>
                    </a>
                  </li>
                  :
                  <>
                    <li className="nav-item me-2">
                      <a className="nav-link btn btn-primary btn-sm" href="#" data-bs-toggle="modal" data-bs-target="#walletModal">
                        <i className="mdi mdi-wallet-plus-outline me-1"></i>
                        <span>{helpers.formatTransactionHash(account, 8, 8)}</span> - <b>{helpers.formatNumberDownRoundWithExtractMax(ethPadBadBalance, 4)}</b> ETHPAD
                      </a>
                    </li>
                    {
                      kycStatus === 'START' &&
                      <li className="nav-item me-2">
                        <button className="nav-link btn btn-warning btn-sm"
                          onClick={() => handleOnclickKyc()}
                          id="bnt-kyc-start">
                          <i className="mdi mdi-file-edit-outline me-1"></i>
                          <span>KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'PENDING' &&
                      <li className="nav-item me-2">
                        <button className="nav-link btn btn-primary btn-sm"
                          onClick={() => handleOnclickKyc()}>
                          <i className="mdi mdi-clock-outline"></i>
                          <span> KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'APPROVED' &&
                      <li className="nav-item me-2">
                        <button className="nav-link btn btn-success btn-sm"
                          // onClick={() => handleOnclickKyc()}
                          >
                          <i className="mdi mdi-check"></i>
                          <span> KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'ERROR' &&
                      <li className="nav-item me-2">
                        <button className="nav-link btn btn-danger btn-sm"
                          onClick={() => handleOnclickKyc()}
                        >
                          <i className="mdi mdi-close me-1"></i>
                          <span> KYC</span>
                        </button>

                      </li>
                    }
                  </>
              }
              <li className="nav-item me-2">
                <NavLink activeClassName="active" className="nav-link" aria-current="page" to={ROUTES.PROJECTS} onClick={handGotToProject}>
                  <i className="mdi mdi-fire me-1"></i>
                  <span>Projects</span>
                </NavLink>
              </li>
              <li className="nav-item me-2">
                <NavLink activeClassName="active" className="nav-link" aria-current="page" to={ROUTES.STACKING} >
                  <span>Staking</span>
                </NavLink>
              </li>
              {/* <li className="nav-item me-2">
                <NavLink activeClassName="active" className="nav-link" to={ROUTES.CALENDAR}>
                  <span>Calendar</span>
                </NavLink>
              </li> */}

              <li className="nav-item me-2">
                <a href={`https://bridge-ethpad.network`} target="blank" aria-current="page" className="nav-link">
                  <i className="mdi mdi-bridge me-1"></i><span>Bridge</span>
                </a>
              </li>


            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
